import * as React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'

const Title = ({ title }) => {
    return(
        <TitleStyled>
            <h3>{title}</h3>
        </TitleStyled>
    )
}

export default Title

const TitleStyled = styled.div`
    max-width: 550px;
    margin: 4rem auto 4rem auto;
    border-bottom: 3px solid ${color.yellow};
    padding-bottom:1rem;

    ${media.s`
        margin: 27px auto 4rem auto;
        width: 281px;
        padding-bottom: 10px;
        padding-top: 7px;
    `}

    h3{
        font-weight:600;
        text-transform: uppercase;
        font-size: 1.5rem;
        letter-spacing: 0.5rem;
        text-align: center;
    }
`